import queryString from 'query-string';
import { AssignType, RequestTypes } from '@/types';
import { FilterType } from './constants';
import { formatUTCDateTime } from './timeformat';
import { omit } from 'lodash';

export function getFirstLetter(str) {
  return str.charAt(0).toUpperCase();
}

export function getAvatarName(fullname) {
  if (!fullname) return '';
  const nameArray = fullname.split(' ');
  return (
    getFirstLetter(nameArray[0]) +
    (nameArray.length > 1 ? getFirstLetter(nameArray[nameArray.length - 1]) : '')
  );
}

export function getHyperText(text) {
  let hyperText = text || '';
  const markdownLinkPattern = /\[(.*?)\]\((.*?)\)/g;
  const markdownLinks = [...hyperText.matchAll(markdownLinkPattern)];
  markdownLinks?.forEach(
    ([match, title, url]) =>
      (hyperText = hyperText.replace(match, `<a href='${url}' target='_blank'>${title}</a>`)),
  );
  const urlRegex = /(?<!href=['"])(https?:\/\/[^\s]+)/g;
  const urls = hyperText.match(urlRegex);
  urls?.forEach(
    (url) => (hyperText = hyperText.replace(url, `<a href='${url}' target='_blank'>${url}</a>`)),
  );
  return hyperText;
}

export function getAvatarType(fullname) {
  const name = fullname.toLowerCase();
  let type = '';
  if (name.includes('amazon')) type = AssignType.amazon;
  if (name.includes('helpdesk') || name.includes('help desk')) type = AssignType.helpdesk;
  if (name.includes('workflow')) type = AssignType.workflow;
  return type;
}

export function isValidateEmail(email) {
  return (
    email &&
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
  );
}

export function isValidWebLink(link) {
  const regex = /^https?:\/\/([\w.-]+)(?:\.[a-z]{2,})+([/?].*)?$/;
  return regex.test(link);
}

export function getValidString(multiLineStr) {
  return multiLineStr
    ?.replace(/&quot;/g, '"')
    ?.replace(/&amp;/g, '&')
    ?.replace(/&lt;/g, '<')
    ?.replace(/&gt;/g, '>');
}

export function isJsonString(str) {
  try {
    var json = JSON.parse(str);
    return typeof json === 'object';
  } catch (e) {
    return false;
  }
}

export const convertToBool = (str) => str === true || str?.toLowerCase() === 'true';

export const getFiltersQuery = (pageNumber, rowsPerPage, filters, search) => {
  const query = {};
  query.page = pageNumber;
  query.count = rowsPerPage;
  query.owner = filters[FilterType.owner]?.selectedValues || [];
  query.assign = filters[FilterType.assign]?.selectedValues || [];
  query.status = filters[FilterType.status]?.selectedValues || [];
  query.account = filters[FilterType.account]?.selectedValues || [];
  query.services = filters[FilterType.service]?.selectedValues || [];
  query.types = filters[FilterType.type]?.selectedValues || [];
  query.createdAfter = filters[FilterType.createdAfter]?.selectedValues || [];
  query.search = search;

  return query;
};

export const getLogsEndpointURL = (origin, orgId, query, sort, csvDownload = false) => {
  const url = new URL(origin);
  url.searchParams.set('organizationId', orgId);
  if (!csvDownload) {
    url.searchParams.set('page', query.page);
    url.searchParams.set('count', query.count);
  } else {
    url.searchParams.set('download', true);
  }
  if (query.owner.length > 0) url.searchParams.set('ownedByIds', query.owner);
  if (query.assign.length > 0) url.searchParams.set('assignedToIds', query.assign);
  if (query.status.length > 0) url.searchParams.set('stateIds', query.status);
  if (query.account.length > 0) url.searchParams.set('organizationIds', query.account);
  if (query.services.length > 0) url.searchParams.set('services', query.services);
  if (query.types.length > 0 && query.types[0] !== RequestTypes.all)
    url.searchParams.set('isRequest', query.types);
  if (query.search) url.searchParams.set('search', query.search);
  if (sort && sort.key && sort.dir)
    url.searchParams.set('sortColumns', `${sort.key} ${sort.dir.toUpperCase()}`);
  if (query.createdAfter.length > 0)
    url.searchParams.set('createdAfter', query.createdAfter.map(formatUTCDateTime));

  return url.toString();
};

export const getValidURL = (path, searchParam) => {
  return `${path}?${queryString.stringify(
    omit(queryString.parse(searchParam), ['code', 'state']),
  )}`;
};
