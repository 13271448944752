import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import clsx from 'clsx';

import { withStore, userStore } from '@/services/store';
import {
  Button,
  Typography,
  MultiLineInput,
  Dropdown,
  TypeaheadDropdown,
  TextField,
  LoadingIndicator,
  ConfirmDialog,
  DateTimePicker,
  CompactAttachment,
  Tooltip,
} from '@/components';
import { NotificationText, PriorityOptions, UrlSearch } from '@/types';
import { getFileSizeError, isValidQuantity, showErrorNotification } from '@/utils';
import { theme } from '@/config';
import { MAX_QUANTITY, SECOND, WARNING_COLOR } from '@/utils/constants';
import { useCreateTaskStore } from '../stores/useCreateTaskStore';
import SpecificForm from '@/services/AutoWorkflow/SpecificForms/SpecificForm';

import styles from './GeneralForm.module.css';
import { IconButton } from '@mui/material';
import { StyledCloseIcon } from '@/components/Modal/Modal';

const PAGE_NAME = 'GeneralForm';

const GeneralForm = ({ goBack, onClose, handleClose }) => {
  const createTaskStore = useCreateTaskStore();
  const { variationStore, workflowStore, routerStore } = createTaskStore;
  const [isCreating, setIsCreating] = useState(false);
  const workflowType = routerStore.getSearchParam(UrlSearch.workflowType);
  const [quantityErrorOpen, setQuantityErrorOpen] = useState(false);
  const isCreateQuestionPage = createTaskStore.isQuestion;
  const isValidRequest =
    variationStore.title &&
    variationStore.assignTo &&
    !getFileSizeError(variationStore.attachedFiles) &&
    (isCreateQuestionPage ||
      (variationStore.taskOwner &&
        variationStore.estimatedTime &&
        isValidQuantity(variationStore.asinQuantity)));
  const isValidWorkflow =
    (!workflowStore.isSingleServiceType && workflowStore.isValidTemplate) ||
    (workflowStore.isSingleServiceType && workflowStore.staticFormValue.isValid);
  const isSubmitDisabled =
    !isValidRequest || (workflowStore.workflow === 'WeeksOfCover' && !isValidWorkflow);

  useEffect(() => {
    if (!isCreateQuestionPage) variationStore.setASINQuantity(createTaskStore.baseQuantity);
  }, [createTaskStore.workflowInfo]);

  useEffect(() => {
    if (Number(variationStore.asinQuantity) >= MAX_QUANTITY) {
      setQuantityErrorOpen(true);
    } else {
      setQuantityErrorOpen(false);
    }
  }, [variationStore.asinQuantity]);

  useEffect(() => {
    if (userStore.currentUser) {
      variationStore.setTaskOwner(userStore.currentUser.id);
      variationStore.setAssignTo(createTaskStore.unassigned);
    }
  }, [userStore.currentUser, createTaskStore.unassigned]);

  const handleSubmitTicket = async () => {
    if (!userStore.currentUser.isContributor) {
      showErrorNotification(NotificationText.createTicketPermissionWarning, {
        autoHideDuration: SECOND,
      });
      return;
    }
    if (!isSubmitDisabled) {
      setIsCreating(true);
      try {
        const ticketInfo = await workflowStore.submitWorkflow(workflowType, {
          ...workflowStore.template,
          ...(!workflowStore.defaultConfig.hasSpecificData
            ? workflowStore.staticFormValue.value
            : workflowStore.specificData),
        });
        if (ticketInfo.ticketId) {
          workflowStore.reset();
          variationStore.clearData();
          handleClose(ticketInfo.ticketId);
        }
      } catch (err) {
        showErrorNotification(err.message);
      }
      setIsCreating(false);
    }
  };

  const handleCancel = () => {
    if (isCreateQuestionPage) {
      onClose();
    } else {
      goBack(true);
    }
  };

  const renderAssign = (value, userOptions, helperText = 'Select Assignee') => {
    const assignItem = userStore.getAssign(value, userOptions);
    if (!assignItem || !assignItem.label)
      return (
        <Typography
          variant="body1"
          id={`${PAGE_NAME}_assignValue`}
          className={styles.placeholder}
          style={{
            color: theme.palette.neutrals.greyMed,
          }}
        >
          {helperText}
        </Typography>
      );
    return <Typography variant="body1">{assignItem.label || 'None'}</Typography>;
  };

  if (isCreating || !workflowStore.template) {
    return (
      <div id={`${PAGE_NAME}_loading`} className={styles.loadingContainer}>
        <LoadingIndicator />
      </div>
    );
  }

  return (
    <div className={styles.createTaskContainer}>
      <div className={styles.createTaskTitle}>
        <Button
          variant="tertiary"
          className={!createTaskStore.isDetailCreateFlyoutPage ? styles.backButton : null}
          startIcon={<ArrowBackIcon />}
          onClick={goBack}
          id={`${PAGE_NAME}_backButton`}
        >
          Back
        </Button>
        <div className={styles.createTaskTitleText}>
          <Typography variant="h4">{createTaskStore.organizationInfo?.name}</Typography>
        </div>
        <div className={styles.createTaskCloseBtn}>
          <Tooltip title="Close" placement="bottom" arrow>
            <IconButton
              onClick={onClose}
              classes={{ root: styles.closeButton }}
              id="Modal_CloseIconButton"
            >
              <StyledCloseIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>

      <div className={styles.contentContainer}>
        <Typography variant="h5">{createTaskStore.workflowInfo?.title}</Typography>

        <Typography variant="body1">{createTaskStore.workflowInfo?.description}</Typography>

        <div className={styles.row}>
          <div className={clsx(styles.contentGroup, styles.fullWidth)}>
            <MultiLineInput
              label={variationStore.titleLabel}
              placeholder={variationStore.titlePlaceholder}
              isHelper
              maxLength={160}
              error={!variationStore.title}
              value={variationStore.title}
              onChange={(value) => variationStore.setTitle(value)}
              id={`${PAGE_NAME}_taskTitle`}
            />
            <MultiLineInput
              label="Description"
              placeholder={variationStore.descriptionPlaceholder}
              style={{ marginTop: -20, marginBottom: 8 }}
              value={variationStore.description}
              onChange={(value) => variationStore.setDescription(value)}
              id={`${PAGE_NAME}_description`}
            />
            <SpecificForm showWarning={isValidRequest} />
          </div>
          <div className={styles.contentGroup}>
            {!isCreateQuestionPage && (
              <Dropdown
                label="Priority*"
                id={`${PAGE_NAME}_priority`}
                placeholder="Select Priority"
                value={variationStore.priority}
                options={PriorityOptions}
                onChange={(e) => variationStore.setPriority(e.target.value)}
              />
            )}
            <TypeaheadDropdown
              label={`${isCreateQuestionPage ? 'Question' : 'Service'} Owner*`}
              tooltipText={`The owner monitors the progress of the ${
                isCreateQuestionPage ? 'question' : 'task'
              }.`}
              id={`${PAGE_NAME}_taskOwner_dropdown`}
              value={variationStore.taskOwner}
              options={createTaskStore.ownerOptions}
              error={!variationStore.taskOwner}
              classes={{
                root: styles.noFlex,
                container: styles.assignContainer,
                svgIcon: styles.moreIcon,
              }}
              renderValue={(value) =>
                renderAssign(value, createTaskStore.ownerOptions, 'Select Owner')
              }
              onChange={(value) => variationStore.setTaskOwner(value)}
            />
            <TypeaheadDropdown
              label="Assign to*"
              id={`${PAGE_NAME}_assignTo_dropdown`}
              error={!variationStore.assignTo}
              value={variationStore.assignTo}
              options={createTaskStore.assignOptions}
              classes={{
                root: styles.noFlex,
                container: styles.assignContainer,
                svgIcon: styles.moreIcon,
              }}
              renderValue={(value) => renderAssign(value, createTaskStore.assignOptions)}
              onChange={(value) => variationStore.setAssignTo(value)}
            />
            {!isCreateQuestionPage && (
              <>
                <TextField
                  type="number"
                  label={variationStore.quantityLabel}
                  placeholder={variationStore.quantityPlaceholder}
                  value={variationStore.asinQuantity}
                  error={!isValidQuantity(variationStore.asinQuantity)}
                  onChange={(e) => variationStore.setASINQuantity(e.target.value)}
                  id={`${PAGE_NAME}_quantity_textField`}
                />
                <DateTimePicker
                  isDate
                  id={`${PAGE_NAME}_ECD_datePicker`}
                  label={<div className={styles.estimatedTime}>Estimated Completion Date*</div>}
                  helperText="Select Date"
                  value={variationStore.estimatedTime}
                  error={!variationStore.estimatedTime}
                  onChange={(value) => variationStore.setEstimatedTime(value)}
                  className={styles.dateTimePicker}
                  style={{ flex: '0 1' }}
                />
              </>
            )}
            <CompactAttachment
              contentClassName={styles.uploader}
              files={variationStore.attachedFiles}
              error={getFileSizeError(variationStore.attachedFiles)}
              onUpdateFile={(files) => variationStore.setAttachedFiles(files)}
            />
          </div>
        </div>
        <div className={styles.buttonGroup}>
          {isValidRequest && !isValidWorkflow && !isCreateQuestionPage && (
            <div className={styles.requirementsMsg}>
              <Typography
                variant="subtitle2"
                className={clsx({
                  [styles.errorLabel]: isSubmitDisabled,
                  [styles.warningLabel]: !isSubmitDisabled,
                })}
                tooltipText={`Automation requires the inputs highlighted in yellow are provided.`}
              >
                {'Automation requirements not met'}
              </Typography>
            </div>
          )}
          <div>
            <Button
              size="large"
              variant="primary"
              onClick={handleSubmitTicket}
              disabled={isSubmitDisabled}
              id={`${PAGE_NAME}_submitButton`}
              tooltipText={
                !userStore.currentUser.isContributor
                  ? NotificationText.createTicketPermissionWarning
                  : ''
              }
              style={{
                background:
                  isValidRequest && !isValidWorkflow && !isCreateQuestionPage && !isSubmitDisabled
                    ? `${WARNING_COLOR} !important`
                    : '',
              }}
            >
              Submit
            </Button>
            <Button
              size="large"
              variant="tertiary"
              onClick={handleCancel}
              id={`${PAGE_NAME}_cancelButton`}
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>

      {quantityErrorOpen && (
        <ConfirmDialog
          open
          containerClassName={styles.errorMsgDialog}
          title=""
          description="Please input a lower quantity"
          okText="Got it"
          onOk={() => setQuantityErrorOpen(false)}
        />
      )}
    </div>
  );
};

export default withStore(observer(GeneralForm));
