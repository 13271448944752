import { action, computed, makeObservable, observable } from 'mobx';
import { ColumnType } from '@/types';

const HEADER_COLUMNS = [
  {
    key: ColumnType.state,
    label: 'Status',
    align: 'left',
    sortKey: 'state',
    width: 70,
  },
  {
    key: ColumnType.accountId,
    label: 'Account',
    align: 'left',
    sortKey: 'organization',
    width: 100,
  },
  {
    key: ColumnType.intentType,
    label: 'Service',
    align: 'left',
    sortKey: 'workflow',
    width: 120,
  },
  {
    key: ColumnType.description,
    label: 'Title',
    align: 'left',
    sortKey: 'title',
    width: 350,
  },
  {
    key: ColumnType.ownedByName,
    label: 'Owner',
    align: 'left',
    sortKey: 'OwnedByName',
    width: 70,
  },
  {
    key: ColumnType.assignedName,
    label: 'Assigned To',
    align: 'left',
    sortKey: 'AssignedToName',
    width: 70,
  },
  {
    key: ColumnType.commenterName,
    label: 'Commented By',
    align: 'left',
    sortKey: 'CommentedByName',
    width: 70,
  },
  {
    key: ColumnType.lastUpdated,
    label: 'Last Updated',
    align: 'left',
    sortKey: 'UpdatedOn',
    width: 70,
  },
  {
    key: ColumnType.createdDate,
    label: 'Date Created',
    align: 'left',
    sortKey: 'createdOn',
    width: 70,
  },
  {
    key: ColumnType.estimatedDate,
    label: 'ECD',
    align: 'left',
    sortKey: 'estimatedCompletion',
    width: 70,
  },
];

export class TableStore {
  hoveredRow = null;
  rowsPerPage = 10;
  pageNumber = 1;
  sortKey = 'createdOn';
  sortDirection = 'desc';

  constructor(accountStore, filterStore) {
    makeObservable(this, {
      headerColumns: computed,
      hoveredRow: observable,
      pageNumber: observable,

      sortKey: observable,
      setSortKey: action,
      sortDirection: observable,
      setSortDirection: action,

      rowsPerPage: observable,
      setRowsPerPage: action,
    });

    this.accountStore = accountStore;
    this.filterStore = filterStore;
  }

  setSortKey(key) {
    if (key) {
      this.sortKey = key;
    }
  }

  setSortDirection(dir) {
    if (dir) {
      this.sortDirection = dir;
    }
  }

  // NOTE: Get table header columns
  get headerColumns() {
    let headers = HEADER_COLUMNS;
    if (!this.filterStore.isEstimatedVisible) {
      headers = headers.filter((item) => item.key !== ColumnType.estimatedDate);
    }
    if (this.filterStore.getSelectedCount(ColumnType.priority) !== 0) {
      return headers;
    } else {
      return headers.filter((item) => item.key !== ColumnType.priority);
    }
  }

  // NOTE: Table actions
  onMouseEnter(logId) {
    this.hoveredRow = logId;
  }

  onMouseLeave() {
    this.hoveredRow = null;
  }

  // NOTE: Sort table rows by order
  toggleSort(sortKey) {
    let direction = 'asc';
    if (this.sortKey === sortKey) {
      direction = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      direction = 'desc';
    }
    this.sortKey = sortKey;
    this.sortDirection = direction;
  }

  // NOTE: Check if current column is active
  isActiveSort(sortKey) {
    return this.sortKey === sortKey;
  }

  getSortDirection(sortKey) {
    if (this.sortKey === sortKey) return this.sortDirection;
    return 'asc';
  }

  // NOTE: Pagination
  setRowsPerPage(rows) {
    this.rowsPerPage = rows;
  }

  setPageNumber(page) {
    this.pageNumber = page;
  }

  dispose() {
    // TBD
  }
}
