import { useEffect, useState, useMemo } from 'react';
import cx from 'clsx';
import { isEqual } from 'lodash';
import { IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { formatLocalDateTime, getFileSizeError, getHyperText } from '@/utils';
import { Avatar, Button, Modal, MoreButton, MultiLineInput, Typography } from '@/components';
import { CommentAttachmentArea } from './CommentAttachmentArea';
import { userStore } from '@/services/store';
import styles from './CommentItem.module.css';

const PAGE_NAME = 'CommentItem';

export const CommentItem = ({
  comment,
  createdByYou,
  addingFiles,
  deletingFiles,
  handleCommentUpdate,
  handleCommentDelete,
}) => {
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [editCommentText, setEditCommentText] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [addedFiles, setAddedFiles] = useState(comment.attachments || []);

  const hyperText = useMemo(() => getHyperText(comment.text), [comment.text]);

  useEffect(() => {
    setAddedFiles(comment.attachments || []);
  }, [comment.attachments]);

  const handleSaveClick = () => {
    const deletingFiles = comment.attachments
      ?.filter((item) => !addedFiles?.find((file) => isEqual(file, item)))
      .filter((file) => file.id);
    const addingFiles = addedFiles
      ?.filter((item) => !comment.attachments?.find((file) => isEqual(file, item)))
      ?.filter((file) => !file.id);
    handleCommentUpdate(editCommentText, addingFiles, deletingFiles);
    setIsEditing(false);
  };

  const handleDeleteFile = (file) => {
    setAddedFiles(addedFiles.filter((item) => !isEqual(file, item)));
  };

  const selectFiles = (event) => {
    let newFiles = addedFiles;
    for (const file of event.target.files) newFiles = newFiles.concat(file);
    setAddedFiles(newFiles);
  };

  return (
    <div id={`${PAGE_NAME}_container`} className={styles.container}>
      <Avatar name={comment.assign.name} id={`${PAGE_NAME}_avatar`} variant={comment.assign.type} />
      <div className={styles.contentRoot}>
        <div
          id={`${PAGE_NAME}_contentContainer`}
          className={cx(styles.contentItem, {
            [styles.editContentItem]: isEditing,
            [styles.errorContainer]: getFileSizeError(addedFiles),
          })}
        >
          <div className={styles.titleWrapper}>
            <span id={`${PAGE_NAME}_userName`} className={styles.title}>
              {comment.assign.name}
            </span>
            <span id={`${PAGE_NAME}_createDate`} className={styles.lastUpdateAt}>
              {formatLocalDateTime(comment.lastUpdated, 'hh:mm A, MM/DD/YY')}
            </span>
          </div>
          {!isEditing ? (
            <div className={styles.messageContainer}>
              <div className={styles.contentContainer}>
                <span
                  id={`${PAGE_NAME}_messageContent`}
                  className={styles.messageContent}
                  dangerouslySetInnerHTML={{ __html: hyperText }}
                />
                {(comment.attachments?.length > 0 || addingFiles?.length > 0) && (
                  <CommentAttachmentArea
                    files={comment.attachments}
                    addingFiles={addingFiles}
                    deletingFiles={deletingFiles}
                  />
                )}
              </div>
              <div className={styles.actionContainer}>
                <div>
                  {(createdByYou || userStore.isWFLAdminUser) && (
                    <MoreButton
                      id={`${PAGE_NAME}_moreButton`}
                      onUpdate={
                        createdByYou
                          ? () => {
                              setEditCommentText(comment.text);
                              setIsEditing(true);
                            }
                          : null
                      }
                      onDelete={() => setIsDeleteOpen(true)}
                    />
                  )}
                </div>
                {comment.createdDate &&
                  comment.lastUpdated &&
                  comment.createdDate !== comment.lastUpdated && (
                    <span className={styles.lastUpdateAt}>(Edited)</span>
                  )}
              </div>
            </div>
          ) : (
            <>
              <MultiLineInput
                hiddenLabel
                autoFocus
                isTransparent
                id={`${PAGE_NAME}_message_multiLineInput`}
                placeholder="Provide a comment of the task"
                defaultValue={comment.text}
                className={styles.messageContent}
                onChange={(value) => setEditCommentText(value)}
              >
                {editCommentText}
              </MultiLineInput>
              {addedFiles?.length > 0 && (
                <CommentAttachmentArea files={addedFiles} onDeleteFile={handleDeleteFile} />
              )}
              <div id={`${PAGE_NAME}_buttonGroup_container`} className={styles.buttonGroup}>
                <IconButton
                  component="label"
                  id={`${PAGE_NAME}_editing_attach_button`}
                  classes={{ root: styles.attachButton }}
                >
                  <AttachFileIcon className={styles.attachIcon} />
                  <input
                    type="file"
                    multiple
                    accept=""
                    className={styles.fileInput}
                    onChange={selectFiles}
                  />
                </IconButton>
                <div style={{ display: 'flex', gap: 8 }}>
                  <Button
                    id={`${PAGE_NAME}_cancel_button`}
                    size="large"
                    variant="secondary"
                    onClick={() => {
                      setEditCommentText('');
                      setIsEditing(false);
                      setAddedFiles(comment.attachments);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    id={`${PAGE_NAME}_save_button`}
                    size="large"
                    variant="primary"
                    disabled={
                      !editCommentText && (addedFiles?.length === 0 || getFileSizeError(addedFiles))
                    }
                    onClick={handleSaveClick}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
        {getFileSizeError(addedFiles) && (
          <Typography variant="subtitle2" className={styles.errorLabel}>
            File size exceeded limit (20MB).
          </Typography>
        )}
      </div>
      {isDeleteOpen && (
        <Modal
          id={`${PAGE_NAME}_deleteModal`}
          open={isDeleteOpen}
          isCloseIconHidden
          title="Delete Message"
          onClose={() => setIsDeleteOpen(false)}
          titleClassName={styles.deleteModalTitle}
          contentClassName={styles.deleteCommentContent}
          actions={
            <>
              <Button
                size="large"
                onClick={() => {
                  handleCommentDelete();
                  setIsDeleteOpen(false);
                }}
                id={`${PAGE_NAME}_deleteQuestion`}
              >
                Delete
              </Button>
              <Button
                size="large"
                variant="tertiary"
                onClick={() => setIsDeleteOpen(false)}
                id={`${PAGE_NAME}_cancelRemoveButton`}
              >
                Cancel
              </Button>
            </>
          }
        >
          <Typography variant="h6" className={styles.deleteCommentSubtitle}>
            Are you sure you want to delete this message? This cannot be undone.
          </Typography>
          {comment.text && <span className={styles.contentItem}>{comment.text}</span>}
          {comment.attachments?.length > 0 && (
            <div className={styles.attachmentArea}>
              <CommentAttachmentArea files={comment.attachments} maxWidth={420} />
            </div>
          )}
        </Modal>
      )}
    </div>
  );
};
