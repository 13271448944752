import { observer } from 'mobx-react-lite';
import cx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { BASE_ROUTES, ColumnType, UrlSearch } from '@/types';
import { StatusCell, DateTimeCell, TextCell, AvatarCell } from '@/components/Table';
import { useMainStore } from '@/pages/main/stores/useMainStore';
import { TableCell } from '@mui/material';

export const useStyles = makeStyles((theme) => ({
  bodyLastLeftCell: {
    borderBottomLeftRadius: '10px',
  },
  bodyLastRightCell: {
    borderBottomRightRadius: '10px',
  },
  tableRow: {
    verticalAlign: 'top !important',
    display: 'table-row',
    textDecoration: 'none',
  },
  row: {
    background: theme.palette.neutrals.white,
    '&:hover': {
      cursor: 'pointer',
      background: `${theme.palette.neutrals.white} !important`,
      boxShadow: '0px 4px 4px rgba(177, 185, 205, 0.5) !important',
      position: 'sticky',
    },
  },
}));

const PAGE_NAME = 'TaskLogTableRow';

function getMatch(text, search) {
  if (!search) return null;
  const startIndex = text.toLowerCase().indexOf(search.toLowerCase());
  if (startIndex === -1) return null;
  return [startIndex, startIndex + search.length];
}

export const TaskLogTableRow = observer(({ log, isLastRow }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { accountStore, tableStore, filterStore, routerStore } = useMainStore();

  return (
    <a
      id={`${PAGE_NAME}_container`}
      className={cx(styles.tableRow, {
        [styles.row]: 1,
        [styles.bodyLastLeftCell]: isLastRow,
        [styles.bodyLastRightCell]: isLastRow,
      })}
      onMouseEnter={() => tableStore.onMouseEnter(log[ColumnType.id])}
      onMouseLeave={() => tableStore.onMouseLeave()}
      onClick={(e) => {
        e.preventDefault();
        navigate(
          `${BASE_ROUTES.detail}/?${UrlSearch.ticket}=${log.ticketId}${routerStore.getMainParams(
            '&&',
          )}`,
        );
        return false;
      }}
      href={`${BASE_ROUTES.detail}/?${UrlSearch.ticket}=${log.ticketId}`}
    >
      <StatusCell
        id={`${PAGE_NAME}_statusCell`}
        value={log[ColumnType.state]}
        isLastRow={isLastRow}
      />
      <TextCell
        id={`${PAGE_NAME}_accountName`}
        column={ColumnType.accountName}
        value={log[ColumnType.accountName]}
      />
      <TextCell
        id={`${PAGE_NAME}_type`}
        column={ColumnType.intentType}
        value={log[ColumnType.intentType]}
      />
      <TextCell
        id={`${PAGE_NAME}_title`}
        column={ColumnType.description}
        value={log[ColumnType.description]}
        match={getMatch(log[ColumnType.description], filterStore.filteredSearch)}
      />
      <AvatarCell
        id={`${PAGE_NAME}_ownerCell`}
        value={log[ColumnType.ownedByName]}
        variant={log[ColumnType.ownerType]}
        isRequest
      />
      <AvatarCell
        id={`${PAGE_NAME}_assignCell`}
        value={log[ColumnType.assignedName]}
        variant={log[ColumnType.assignType]}
        isRequest
      />
      {log[ColumnType.commenterName] ? (
        <AvatarCell
          id={`${PAGE_NAME}_commenterCell`}
          value={log[ColumnType.commenterName]}
          variant={log[ColumnType.commenterType]}
          isRequest
        />
      ) : (
        <TableCell />
      )}
      <DateTimeCell
        id={`${PAGE_NAME}_createdDate`}
        column={ColumnType.lastUpdated}
        value={log[ColumnType.lastUpdated]}
      />
      <DateTimeCell
        id={`${PAGE_NAME}_createdDate`}
        column={ColumnType.createdDate}
        value={log[ColumnType.createdDate]}
        isLastRow={!filterStore.isEstimatedVisible && isLastRow}
      />
      {filterStore.isEstimatedVisible && (
        <DateTimeCell
          id={`${PAGE_NAME}_ecd`}
          column={ColumnType.estimatedDate}
          value={log[ColumnType.estimatedDate]}
          isLastRow={isLastRow}
        />
      )}
    </a>
  );
});
