import { makeObservable, action, observable, computed } from 'mobx';
import { ServiceTypes } from '@/types';
import { WORKFLOW_CONFIGS } from '../constants';
import { omit } from 'lodash';

export class WorkflowStore {
  isEditing = false;
  mainStore = null;
  workflowSchemaData = null;
  templateDescription = '';
  templateFiles = {};
  specificData = null;
  errorMessage = '';
  serviceType = ServiceTypes.single;
  skipExecution = false;
  staticFormValue = { value: null, isValid: false };

  constructor(mainStore) {
    makeObservable(this, {
      templateFiles: observable,
      selectFile: action,
      errorMessage: observable,

      isValidTemplate: computed,
      template: computed,

      reset: action,
      clearTemplateFile: action,
      setIsLoading: action,

      isEditing: observable,
      setIsEditing: action,

      staticFormValue: observable,
      updateStaticFormValue: action,

      specificData: observable,
      setSpecificData: action,
      updateSpecificData: action,

      serviceType: observable,
      isSingleServiceType: computed,
      setServiceType: action,

      skipExecution: observable,
      setSkipExecution: action,
    });

    this.mainStore = mainStore;
  }

  clearTemplateFile(field) {
    if (field) this.templateFiles = omit(this.templateFiles, field);
    else this.templateFiles = {};
    this.specificData = null;
  }

  reset() {
    this.isEditing = false;
    this.clearTemplateFile();
    this.isTemplateConfigured = ServiceTypes.single;
    this.skipExecution = false;
    this.staticFormValue = { value: null, isValid: false };
  }

  selectFile(field, file) {
    this.templateFiles[field] = file;
  }

  get defaultConfig() {
    return WORKFLOW_CONFIGS[this.workflow];
  }

  get isValidTemplate() {
    const attachments = this.defaultConfig?.attachments;
    return attachments?.every((field) => Boolean(this.templateFiles[field]));
  }

  setIsLoading(value) {
    this.mainStore.setIsLoading(value);
  }

  updateStaticFormValue(value, isValid) {
    this.staticFormValue = { value, isValid };
  }

  setSpecificData(value) {
    this.specificData = value;
  }

  updateSpecificData(field, value) {
    if (!this.specificData) this.specificData = { [field]: value };
    else this.specificData[field] = value;
  }

  get workflow() {
    return this.mainStore.workflowInfo?.workflow;
  }

  get template() {
    return this.defaultConfig?.template;
  }

  setIsEditing(value) {
    this.isEditing = value;
  }

  setServiceType(value) {
    this.serviceType = value;
  }

  get isSingleServiceType() {
    return this.serviceType === ServiceTypes.single;
  }

  setSkipExecution(value) {
    this.skipExecution = value;
  }

  async submitWorkflow(workflowType, configTemplateValue) {
    const variationStore = this.mainStore.variationStore;
    const templateFile = !this.isSingleServiceType ? this.templateFiles : null;

    const ticketId = await variationStore.submitTicket(
      workflowType,
      {
        ...configTemplateValue,
        SkipExecution: this.skipExecution,
      },
      templateFile,
    );
    if (ticketId) {
      this.reset();
    }
    return { ticketId };
  }

  dispose() {
    this.accountStore.dispose();
  }
}
