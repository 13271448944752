import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import cx from 'clsx';
import Dropzone from 'react-dropzone';

import { makeStyles } from '@mui/styles';
import { UploadIcon } from '@/assets/icons';
import Typography from '../Typography';
import { AttachmentArea } from './AttachmentArea';

const useStyles = makeStyles((theme) => ({
  imageUploader: {
    position: 'relative',
    fontSize: '14px',
    width: '338px',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
  },
  dropzone: {
    width: '100%',
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    color: theme.palette.neutrals.greyMed,
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23AFAFAF' stroke-width='1' stroke-dasharray='12%2c 12' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e")`,
    borderRadius: '16px',
    cursor: 'pointer',
  },
  activeDropzone: {
    background: 'transparent',
  },
  errorZone: {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23F34E4E' stroke-width='1' stroke-dasharray='12%2c 12' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
  warning: {
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='16' ry='16' stroke='%23FF917B' stroke-width='1' stroke-dasharray='12%2c 12' stroke-dashoffset='8' stroke-linecap='square'/%3e%3c/svg%3e")`,
  },
  dropZoneWithImage: {
    background: '#fff',
    border: '1px solid #E8E8E8',
  },
  uploadIcon: {
    fontSize: '48px !important',
  },
  selectFileWrapper: {
    marginLeft: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  selectFile: {
    color: `${theme.palette.primary.purple} !important`,
    background: 'none !important',
    boxShadow: 'none !important',
    textTransform: 'none !important',
    paddingRight: '4px',
    height: '20px',
    fontFamily: 'Open Sans',
    fontWeight: '700 !important',
    fontSize: '16px !important',
    letterSpacing: '0.15px',
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  fileInput: {
    display: 'none !important',
    userSelect: 'none !important',
  },
  dropLabel: {
    fontSize: '16px',
    lineHeight: '20px',
    color: theme.palette.secondary.darkBlue,
  },
}));

const PAGE_NAME = 'UploadAttachment';

function UploadAttachment({
  files,
  accept,
  warning = false,
  error = false,
  contentClassName,
  dropZoneClassName,
  onUpdateFile,
  multiple,
}) {
  const styles = useStyles();
  const selectFile = (selectedFiles) => {
    onUpdateFile(files.concat(selectedFiles));
  };

  const handleDeleteFile = (file) => {
    onUpdateFile(files.filter((item) => !isEqual(file, item)));
  };

  return (
    <div className={cx(styles.imageUploader, contentClassName)} id={`${PAGE_NAME}_div`}>
      <Dropzone
        accept={accept}
        onDrop={(files) => selectFile(files)}
        className={cx(styles.dropzone, dropZoneClassName, {
          [styles.errorZone]: error,
          [styles.warning]: warning,
        })}
        activeClassName={styles.activeDropzone}
        multiple={multiple}
      >
        <UploadIcon className={styles.uploadIcon} />
        <div className={styles.selectFileWrapper}>
          <span tabIndex={-1} component="label" key="import" className={styles.selectFile}>
            Select file
          </span>
          <div className={styles.dropLabel}>or drag and drop here</div>
        </div>

        <input
          className={styles.fileInput}
          type="file"
          accept={accept}
          onChange={(event) => selectFile(event.target.files)}
          multiple={multiple}
        />
      </Dropzone>

      {files?.length > 0 && (
        <div style={{ marginTop: 12 }} id={`${PAGE_NAME}_AttachedFiles_div`}>
          <AttachmentArea files={files} onDeleteFile={handleDeleteFile} />
        </div>
      )}
    </div>
  );
}

UploadAttachment.propTypes = {
  file: PropTypes.any,
  accept: PropTypes.string,
  onUpdateFile: PropTypes.func.isRequired,
  onDeleteFile: PropTypes.func,
  multiple: PropTypes.bool,
};

UploadAttachment.defaultProps = {
  file: null,
  accept: '',
  onDeleteFile: undefined,
  multiple: true,
};

export default UploadAttachment;
