export const MAX_QUESTION_LENGTH = 250;

export const TicketTypes = 'TicketTypes';
export const TicketStates = 'TicketStates';

export const PLATFORM_TYPE_FIELDS = ['Platform', 'PlatformType'];
export const WORKFLOW_TYPE_FIELD = '$type';

export const FilterType = {
  account: 'account',
  status: 'status',
  owner: 'owner',
  assign: 'assign',
  service: 'service',
  type: 'isRequest',
  viewBy: 'viewBy',
  createdAfter: 'createdAfter',
};

export const MAX_QUERY_LENGTH = 2048;
export const MAX_QUANTITY = 100000;

export const SECOND = 1000;
export const SESSION_TIMEOUT = 10000;

export const AUTOPILOT_APP_LINK = 'https://autopilot.profitero.com';
export const FeedbackLink = 'https://form.typeform.com/to/IOW0VuJj';

export const WARNING_COLOR = '#D7A100';
